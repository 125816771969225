<template>
  <div id="add-profession">
    <v-card>
      <v-app-bar
        color="primary"
        dense
        dark
        elevate-on-scroll
        scroll-target="#scrolling-techniques-7"
      >
        <v-toolbar-title>{{ pageTitle }}</v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn icon dark @click="closePrompt">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-app-bar>
      <v-sheet id="scrolling-techniques-7" class="overflow-y-auto" max-height="600">
        <v-container style="overflow: hidden">
          <v-form ref="form1" v-model="valid1" lazy-validation v-on:submit.prevent="submitForm">
            <!-- v-on:submit.prevent="submitForm" -->
            <v-card-title></v-card-title>
            <v-card-text>
              <h6 class="text-danger">* indicates required field</h6>
              <v-container>
                <v-row wrap>
                  <v-col cols="12" sm="6" md="3">
                    <label>
                      <h6>
                        <span class="text-danger">*</span> Year
                      </h6>
                    </label>
                    <v-autocomplete
                      :reduce="(option) => option.value"
                      :loading="YearIdOptionsLoading"
                      :items="YearIdOptions"
                      :rules="YearIdRules"
                      v-model="YearId"
                      required
                      outlined
                      dense
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="6" md="3">
                    <label>
                      <h6>
                        <span class="text-danger">*</span> Education Level
                      </h6>
                    </label>
                    <v-autocomplete
                      :reduce="(option) => option.value"
                      :loading="QualificationIdOptionsLoading"
                      :items="QualificationIdOptions"
                      :rules="QualificationIdRules"
                      v-model="QualificationId"
                      required
                      outlined
                      dense
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="6" md="3">
                    <label>
                      <h6>
                        <span class="text-danger">*</span> Degree
                      </h6>
                    </label>
                    <v-autocomplete
                      :reduce="(option) => option.value"
                      :loading="DegreeIdOptionsLoading"
                      :items="DegreeIdOptions"
                      :rules="DegreeIdRules"
                      v-model="DegreeId"
                      required
                      outlined
                      dense
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="6" md="3">
                    <label>
                      <h6>
                        <span class="text-danger">*</span> Specialization
                      </h6>
                    </label>
                    <v-autocomplete
                      :reduce="(option) => option.value"
                      :loading="SpecilizationIdOptionsLoading"
                      :items="SpecilizationIdOptions"
                      :rules="SpecilizationIdRules"
                      v-model="SpecilizationId"
                      required
                      outlined
                      dense
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                <v-row wrap>
                  <v-col cols="12" sm="12" md="12">
                    <label>
                      <h6>
                        Institution
                        <small>(optional)</small>
                      </h6>
                    </label>
                    <v-text-field
                      v-model="Institution"
                      :rules="InstitutionRules"
                      placeholder="Institution"
                      hint="Enter Institution"
                      required
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row wrap>
                  <v-col align="center" cols="12" md="12">
                    <v-btn
                      @click="closePrompt"
                      elevation="30"
                      shaped
                      tile
                      small
                      color="#a52a2a"
                      class="font-size-h6 px-10 mr-3 white--text"
                    >Close</v-btn>
                    <v-btn
                      :disabled="!valid1"
                      @click.prevent="submitForm"
                      :loading="SubmitFlag"
                      elevation="30"
                      shaped
                      tile
                      small
                      color="#1db954"
                      class="font-size-h6 px-10 mr-3 white--text"
                    >Save</v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-form>
        </v-container>
      </v-sheet>
      <v-card-actions>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import Swal from "sweetalert2";

export default {
  mixins: [common],
  components: {},
  props: {
    pageDescription: {
      type: String
    }
  },
  data() {
    return {
      pageTitle: "Add Education Details",
      pageDescription: "Add Education Details",

      valid1: true,
      valid2: true,
      LoadingFlag: false,
      SearchFlag: false,
      SubmitFlag: false,

      UploadFlag: false,

      YearIdRules: [v => !!v || "Year is required"],
      YearId: "",
      YearIdOptions: [],
      YearIdOptionsLoading: false,

      QualificationIdRules: [v => !!v || "Qualification is required"],
      QualificationId: "",
      QualificationIdOptions: [],
      QualificationIdOptionsLoading: false,

      DegreeIdRules: [v => !!v || "Degree is required"],
      DegreeId: "",
      DegreeIdOptions: [],
      DegreeIdOptionsLoading: false,

      SpecilizationIdRules: [v => !!v || "Specilization is required"],
      SpecilizationId: "",
      SpecilizationIdOptions: [],
      SpecilizationIdOptionsLoading: false,

      Institution: "",
      InstitutionRules: []
    };
  },
  computed: {},
  watch: {
    YearIdOptions: function() {
      console.log("watch YearIdOptions");
      this.LoadingFlag = false;
      this.YearIdOptionsLoading = false;
    },
    QualificationIdOptions: function() {
      console.log("watch QualificationIdOptions");
      this.LoadingFlag = false;
      this.QualificationIdOptionsLoading = false;
    },
    DegreeIdOptions: function() {
      console.log("watch DegreeIdOptions");
      this.LoadingFlag = false;
      this.DegreeIdOptionsLoading = false;
    },
    SpecilizationIdOptions: function() {
      console.log("watch SpecilizationIdOptions");
      this.LoadingFlag = false;
      this.SpecilizationIdOptionsLoading = false;
    }
  },
  created() {
    // this.initialize()
  },
  methods: {
    validateForm() {
      console.log("validateForm called");
      this.$refs.form1.validate();
    },
    resetForm() {
      console.log("resetForm called");
      this.$refs.form1.reset();
      this.closePrompt();
    },
    resetValidation() {
      console.log("resetValidation called");
      this.$refs.form1.resetValidation();
    },
    closePrompt(EducationDetails = {}) {
      console.log("closePrompt called");
      this.$emit("hideAddRecordPrompt", EducationDetails);
    },
    refreshPageData() {
      console.log("refreshPageData called");

      this.YearIdOptionsLoading = true;
      this.getCategoryOptions("YearId", "YearIdOptions", {
        UserInterface: 1,
        CategoryMainId: 9,
        OrderBy: "category_sub_name desc"
      });

      this.QualificationIdOptionsLoading = true;
      this.getCategoryOptions("QualificationId", "QualificationIdOptions", {
        UserInterface: 1,
        CategoryMainId: 10
      });

      this.DegreeIdOptionsLoading = true;
      this.getCategoryOptions("DegreeId", "DegreeIdOptions", {
        UserInterface: 1,
        CategoryMainId: 11
      });

      this.SpecilizationIdOptionsLoading = true;
      this.getCategoryOptions("SpecilizationId", "SpecilizationIdOptions", {
        UserInterface: 1,
        CategoryMainId: 12
      });
    },
    getCategoryOptions(source, destination, condition) {
      console.log("getCategoryOptions called");
      console.log("source=" + source + ", destination=" + destination);
      var n1 = Object.keys(condition).length;
      console.log("n1=" + n1 + ", condition=" + JSON.stringify(condition));
      if (source != "" && destination != "" && n1 > 0) {
        var selectbox1_source = source;
        var selectbox1_destination = destination;
        var selectbox1_url = "api/sub-category/options";
        var selectbox1_conditions_array = condition;
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      }
    },
    submitForm() {
      console.log("submitForm is called");

      var validate1 = this.$refs.form1.validate();
      console.log("validate1=" + validate1);

      if (validate1) {
        var YearId = this.YearId;
        var SpecilizationId = this.SpecilizationId;
        var DegreeId = this.DegreeId;
        var QualificationId = this.QualificationId;
        console.log("YearIdOptions=" + JSON.stringify(this.YearIdOptions));
        console.log("YearId=" + YearId);

        var Year = this.YearIdOptions.find(data => {
          return data.value == YearId;
        });
        var YearTxt = Year.text;

        var Qualification = this.QualificationIdOptions.find(data => {
          return data.value == QualificationId;
        });
        var QualificationTxt = Qualification.text;

        var Degree = this.DegreeIdOptions.find(data => {
          return data.value == DegreeId;
        });
        var DegreeTxt = Degree.text;

        var Specilization = this.SpecilizationIdOptions.find(data => {
          return data.value == SpecilizationId;
        });
        var SpecilizationTxt = Specilization.text;

        var MemberId = this.$session.get("MemberId");

        console.log(
          "YearTxt=" +
            YearTxt +
            "; QualificationTxt=" +
            QualificationTxt +
            "; DegreeTxt=" +
            DegreeTxt +
            "; SpecilizationTxt=" +
            SpecilizationTxt+
            "; MemberId=" +
            MemberId
        );

        var EducationDetails = {
          UserInterface: 1,
          MemberId: MemberId,
          Year: YearId,
          Qualification: QualificationId,
          Degree: DegreeId,
          Specilization: SpecilizationId,
          YearTxt: YearTxt,
          QualificationTxt: QualificationTxt,
          DegreeTxt: DegreeTxt,
          SpecilizationTxt: SpecilizationTxt,
          Institution: this.Institution,
          InsertFlag: true
        };
        this.$emit("hideAddRecordPrompt", EducationDetails);
      } else {
        var message = "";
        if (!validate1) {
          message += "Kindly fill the required fields";
        }
        this.sweetAlert("error", message, false);
      }
    }
  },
  beforeMount() {
    this.refreshPageData();
  }
};
</script>
<style lang="scss">
</style>