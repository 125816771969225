<template>
  <div id="add-training-details">
    <v-overlay :value="ProgressLoadingFlag">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-toolbar color="#8950FC">
      <v-toolbar-title>
        <h1 class="text-white">{{ pageTitle }}</h1>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-btn icon dark @click="closePrompt">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <v-card>
      <v-card-text>
        <div class="d-flex justify-content-center mb-3" v-if="LoadingFlag">
          <b-spinner variant="primary" type="grow" label="Loading..."></b-spinner>
        </div>
        <v-container>
          <v-form ref="form1" v-model="valid1" lazy-validation v-on:submit.prevent="submitForm">
            <v-row wrap>
              <v-col cols="12" sm="3" lg="3" md="3">
                <label>
                  <h6>
                    <span class="text-danger">*</span> Name Of Training
                  </h6>
                </label>
                <v-text-field v-model="Name" :rules="NameRules" outlined dense></v-text-field>
              </v-col>
              <v-col cols="12" sm="3" lg="3" md="3">
                <label>Date</label>
                <v-menu
                  v-model="menu1"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  lazy
                  transition="scale-transition"
                  offset-y
                  full-width
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      required
                      v-model="TrainingDate"
                      label="Date"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="TrainingDate" @input="menu1 = false"></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" sm="3" lg="3" md="3">
                <label>
                  <h6>
                    <span class="text-danger">*</span> Host
                  </h6>
                </label>
                <v-text-field v-model="Host" :rules="HostRules" outlined dense></v-text-field>
              </v-col>
              <v-col cols="12" sm="3" lg="3" md="3">
                <label>
                  <h6>
                    <span class="text-danger">*</span> Pilot Faculty
                  </h6>
                </label>
                <v-text-field v-model="PilotFaculty" :rules="PilotFacultyRules" outlined dense></v-text-field>
              </v-col>
            </v-row>
            <v-row wrap>
              <v-col align="center" cols="12" md="12">
                <v-btn
                  @click.prevent="submitForm()"
                  :loading="submitFlag"
                  color="success"
                  elevation="30"
                  shaped
                  tile
                  large
                  class="btn btn-primary font-size-h6 px-15 py-4 my-3 mr-3 white--text"
                >Submit</v-btn>
                <v-btn
                  @click="closePrompt"
                  color="primary"
                  elevation="30"
                  shaped
                  tile
                  large
                  class="btn btn-primary font-size-h6 px-15 py-4 my-3 mr-3 white--text"
                >Cancel</v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="closePrompt">Cancel</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import Swal from "sweetalert2";

export default {
  mixins: [common],
  components: {},
  props: {
    recordData: {
      required: true
    },
    pageTitle: {
      type: String
    }
  },
  data() {
    return {
      valid1: false,
      valid2: false,
      ProgressLoadingFlag: false,
      submitFlag: false,
      LoadingFlag: false,

      Name: "",
      NameRules: [v => !!v || "Name is required"],
      Host: "",
      HostRules: [v => !!v || "Host is required"],
      PilotFaculty: "",
      PilotFacultyRules: [v => !!v || "Pilot Faculty is required"],

      TrainingDate: new Date().toISOString().slice(0, 10),
      menu1: false
    };
  },
  computed: {},
  watch: {},
  created() {
    // this.initialize()
  },
  methods: {
    closePrompt(TrainingDetails = {}) {
      console.log("closePrompt called");
      console.log({ TrainingDetails });
      this.$emit("hideAddTrainingDetailsPrompt", TrainingDetails);
    },
    refreshPageData() {
      console.log("refreshPageData called");
      this.getTableRecords();
    },
    submitForm() {
      console.log("submitForm is called");

      var validate1 = this.$refs.form1.validate();
      if (validate1) {
        var TrainingDetails = {
          Name: this.Name,
          TrainingDetailsFlag: true,
          Date: this.TrainingDate,
          Host: this.Host,
          PilotFaculty: this.PilotFaculty,
          action: ""
        };
        this.$emit("hideAddTrainingDetailsPrompt", TrainingDetails);
      } else {
        var message = "";
        if (!validate1) {
          message += "Kindly fill the required fields";
        }
        // this.toast("error", message);
        this.sweetAlert("error", output, false);
      }
    }
  },
  beforeMount() {
    this.refreshPageData();
  }
};
</script>
<style lang="scss">
/* This is for documentation purposes and will not be needed in your application */

#add-training-details {
  .preview-content {
    font-size: 14px;
    font-weight: normal;
    line-height: 1.5em;
  }
}
</style>